import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderImage from "../../../components/header/bigHeader/bigHeaderImage"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-bje.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import Specs from "../../../components/specs"
import * as projekteStyles from "../projekte.module.css"
import myLocalVid from '../../../images/projekte/bje-dab/dab-plus-herovid.mp4'
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"


const BuschJaegerDABPlusRadioSimulation = function (props, ref) {

    const data = useStaticQuery(graphql`
        query BuschJaegerDABPlusRadioSimulationQuery {
            heropic: file(relativePath: { eq: "images/projekte/bje-dab/thumbleiste/bje-dab-02.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }
            
            ogimage: file(relativePath: { eq: "images/projekte/bje-dab/thumbleiste/bje-dab-02.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/bje-dab/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }
        }
    `)
  
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Anwendung", "https://www.busch-jaeger.de/produkt/future-linear/busch-radio-dab"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "react"],
        },
      ]

    return (
        <Layout>
            <SEO
                title="Digitalradio DAB+ Simulator"
                description="Q:marketing realisiert Produktsimulation zum DAB+ Digitalradio von Busch-Jaeger."
                image={data?.ogimage?.childImageSharp?.fixed}
            />    

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Digitalradio DAB+ Simulator</h1>
                        <p className="introtext whitetextshadow">Schon vor dem Kauf einschalten und ausprobieren!</p>
                        <AnchorLink to="/projekte/virtuelle-produktpraesentationen/digitalradio-dab-plus-simulator/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Die Funktionen des DAB+ Digitalradios können digital ausprobiert werden.",
                        "Der Interessent kann sich detailliert mit dem Produkt vertraut machen.",
                        "Auch für die Nutzung im Produkttraining optimal.",
                    ]}
                />
            </ListenModule>
 
            <ListenModule headline="Live testen">
                <div className="row">
                    <iframe style={{height:'50vh', width:'100%', border:'0'}} src="https://js-staging.s3.eu-central-1.amazonaws.com/bje-radio-dab/build/index.html"></iframe>
                </div>
            </ListenModule>                             
                         
            
            <Specs data={specsData} />

            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default BuschJaegerDABPlusRadioSimulation